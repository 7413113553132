body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Ubuntu-Bold';
  font-style: normal;
  src: url(../src/fonts/Ubuntu-Bold.ttf) format('truetype'), 
       url(../src/fonts/Ubuntu-Bold.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu-Italic';
  src: url(../src/fonts/Ubuntu-Italic.ttf) format('truetype'), 
       url(../src/fonts/Ubuntu-Italic.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu-Light';
  src: url(../src/fonts/Ubuntu-Light.ttf) format('truetype'), 
       url(../src/fonts/Ubuntu-Light.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu-Medium';
  src: url(../src/fonts/Ubuntu-Medium.ttf) format('truetype'), 
       url(../src/fonts/Ubuntu-Medium.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu-Regular';
  src: url(../src/fonts/Ubuntu-Regular.ttf) format('truetype'), 
       url(../src/fonts/Ubuntu-Regular.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url(../src/fonts/Ubuntu-Regular.ttf) format('truetype'), 
       url(../src/fonts/Ubuntu-Regular.woff) format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../src/fonts/materialicons.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  white-space: pre-line;
}

.DataTables tbody tr td {
  padding: 0.5em;
}

.dummyTR, .dummyTR td, .dummyTR * *, .dummyTR * * {
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  max-height: 1px !important;
  overflow:hidden !important;
  border:0 !important;
}
